import React, { useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import AwesomeSlider from 'react-awesome-slider'
import './styles-slider.scss'

const Slider = ({ slides }) => {
  const [selected, setSelected] = useState(0)
  const [controlDisabled, setControlDisabled] = useState(0)
  const timeoutRef = useRef()
  const ref = useRef(null)

  useEffect(() => {
    timeoutRef.current = null
    timeoutRef.current = setTimeout(() => {
      if (selected === slides.length - 1) {
        setSelected(0)
      } else {
        setSelected(selected + 1)
      }
    }, 6000)

    return () => clearTimeout(timeoutRef.current)
  }, [selected])

  useEffect(() => {
    if (slides) {
      slides.map(s => s.imageLqip).forEach(i => preloadImage(i))
    }
  }, [slides])

  const preloadImage = url => {
    var img = new Image()
    img.src = url
  }

  return (
    <AwesomeSlider
      ref={ref}
      selected={selected}
      bullets={false}
      organicArrows={false}
      buttons={false}
      customContent={
        <div
          className={`awssld__control ${controlDisabled ? 'disabled' : ''}`}
          data-active={selected + 1}
        >
          {slides.slice(0, 3).map((slide, i) => (
            <div
              key={i}
              className={`${selected === i ? 'active' : ''}`}
              onClick={() => setSelected(i)}
            >
              <svg
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                x="0px"
                y="0px"
                viewBox="0 0 17 12"
              >
                <path d="M17,0v12H0h4.9C16.5,12,17,0,17,0z" />
              </svg>
              <svg
                className="mirrored"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                x="0px"
                y="0px"
                viewBox="0 0 17 12"
              >
                <path d="M17,0v12H0h4.9C16.5,12,17,0,17,0z" />
              </svg>
              <span dangerouslySetInnerHTML={{ __html: slide.title }} />
            </div>
          ))}
        </div>
      }
      onTransitionStart={() => setControlDisabled(1)}
      onTransitionEnd={() => setControlDisabled(0)}
    >
      {slides.map((slide, i) => (
        <div key={i}>
          <div dangerouslySetInnerHTML={{ __html: slide.image }} />
        </div>
      ))}
    </AwesomeSlider>
  )
}

Slider.propTypes = {
  slides: PropTypes.array,
}

export default Slider
