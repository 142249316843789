import React from 'react'
import PropTypes from 'prop-types'
import SearchCity from '../SearchCity'
import Slider from './Slider'
import './styles.scss'

import { l10n } from '../../shared/constants'

const {
  common: { sPersonalExpertSupport, sAllJobPostings, sConnect },
} = l10n

class Hero extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}

    this.ref = React.createRef()
  }

  componentDidMount() {
    //
  }

  componentDidUpdate() {
    //
  }

  render() {
    const {
      isMD,
      data: {
        type,
        title,
        text,
        buttons,
        bubble,
        image,
        slides,
        meta,
        breadcrumb,
        propertyType,
        suggestedCities,
        career,
      },
    } = this.props

    if (type === 'normal') {
      return (
        <div className={`hero ${type ? '-' + type : ''}`} ref={this.ref}>
          <div className="hero_ctn">
            <div>
              <div className="row">
                <div
                  className="col-12"
                  dangerouslySetInnerHTML={{ __html: breadcrumb }}
                />
              </div>
              <div className="row">
                <div className="col-12">
                  <h1 dangerouslySetInnerHTML={{ __html: title }} />
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <p dangerouslySetInnerHTML={{ __html: text }} />
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <SearchCity
                    posttype={propertyType}
                    suggestedCities={suggestedCities}
                  />
                </div>
              </div>
              {career && (
                <div className="row">
                  <div className="buttons buttonsCareer bg_primary">
                    <a href="#jobs" className="btn btn--secondary btn--rounded">
                      {sAllJobPostings}
                    </a>
                    <a
                      href={`mailto:${career.mail}`}
                      className="btn btn--white btn--rounded"
                    >
                      {sConnect}
                    </a>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="hero_media">
            <div
              className="hero_media_image"
              dangerouslySetInnerHTML={{ __html: image }}
            />
            {career && (
              <div className="hero_media_career">
                <img src={career.kununu_employee} />
                {/* <img src={career.kununu_open_company} /> */}
              </div>
            )}
          </div>
        </div>
      )
    } else if (type === 'half') {
      return (
        <div className={`hero ${type ? '-' + type : ''}`} ref={this.ref}>
          <div className="hero_ctn">
            <div>
              <div className="row">
                <div
                  className="col-12"
                  dangerouslySetInnerHTML={{ __html: breadcrumb }}
                />
              </div>
              <div className="row">
                <div className="col-12">
                  <h1 dangerouslySetInnerHTML={{ __html: title }} />
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  {buttons && (
                    <div className={'hero_buttons'}>
                      <a
                        href={buttons['first_button']['url']}
                        className="btn btn--secondary hero_button"
                        target={
                          buttons['first_button']['target']
                            ? buttons['first_button']['target']
                            : '_self'
                        }
                      >
                        {buttons['first_button']['title']}
                        <i className="material-icons">arrow_forward</i>
                      </a>
                      <a
                        href={buttons['second_button']['url']}
                        className={'btn hero_button btn--newblue'}
                        target={
                          buttons['second_button']['target']
                            ? buttons['second_button']['target']
                            : '_self'
                        }
                      >
                        {buttons['second_button']['title']}
                      </a>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="hero_media">
            <div
              className="hero_media_image"
              dangerouslySetInnerHTML={{ __html: image }}
            />
          </div>
        </div>
      )
    } else if (type === 'full') {
      return (
        <div className={`hero ${type ? '-' + type : ''}`} ref={this.ref}>
          <div className="hero_ctn">
            <div>
              <div className="row">
                <div className="col-12">
                  <h1 dangerouslySetInnerHTML={{ __html: title }} />
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <p dangerouslySetInnerHTML={{ __html: text }} />
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <div className="hero_meta">
                    <SearchCity
                      posttype={propertyType}
                      suggestedCities={suggestedCities}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="hero_media">
            <div
              className="hero_media_image"
              dangerouslySetInnerHTML={{ __html: image }}
            />
          </div>
        </div>
      )
    } else if (type === 'normal_slider') {
      return (
        <div className={`hero ${type ? '-' + type : ''}`} ref={this.ref}>
          <div className="hero_ctn">
            <div>
              <div className="row">
                <div
                  className="col-12"
                  dangerouslySetInnerHTML={{ __html: breadcrumb }}
                />
              </div>
              <div className="row">
                <div className="col-12">
                  <h1 dangerouslySetInnerHTML={{ __html: title }} />
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <p dangerouslySetInnerHTML={{ __html: text }} />
                </div>
              </div>
            </div>
          </div>
          <div className="hero_media">
            <div className="hero_media_slider">
              <Slider slides={slides} />
            </div>
            {bubble && (
              <div className="hero_bubble">
                <span dangerouslySetInnerHTML={{ __html: bubble }} />
              </div>
            )}
          </div>
        </div>
      )
    } else if (type === 'slider') {
      return (
        <div className={`hero ${type ? '-' + type : ''}`} ref={this.ref}>
          <div className="hero_ctn">
            <div>
              <div className="row m-bottom-sm">
                <div className="col-12">
                  <h1 dangerouslySetInnerHTML={{ __html: title }} />
                  <p dangerouslySetInnerHTML={{ __html: text }} />
                </div>
              </div>
              <SearchCity
                posttype={propertyType}
                suggestedCities={suggestedCities}
              />
              <div className="row" style={{ display: 'none' }}>
                <div className="col-12 buttons buttonsEdited m-top-sm">
                  <a
                    className="btn btn--default"
                    href={`${meta.personalExpertSupportLink}`}
                  >
                    {sPersonalExpertSupport}
                    <span>{'\u00a0\u00a0\u00a0|\u00a0\u00a0\u00a0'}</span>
                    {meta.openingHours}
                  </a>
                  <a
                    className="btn btn--default phone"
                    href={`tel:${meta.telephone}`}
                  >
                    {meta.telephone}
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="hero_media">
            <div className="hero_media_slider">
              <Slider slides={slides} />
            </div>
          </div>
          <button
            className="hero_scroll"
            onClick={() => {
              window.scrollTo({
                top: this.ref.current.scrollHeight + (isMD ? 50 : 70),
                behavior: 'smooth',
              })
            }}
          >
            <span />
          </button>
        </div>
      )
    } else {
      return null
    }
  }
}

Hero.propTypes = {
  isMD: PropTypes.bool,
  data: PropTypes.object,
}

export default Hero
